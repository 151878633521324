<template lang="pug">
.box-select-list.d-flex
  Item(
    v-for="(props, index) in items"
    :key="index"
    v-bind="props"
    @click="$emit('select', $event)"
  )
</template>

<script>
  export default {
    components: {
      Item: () => import('./Item.vue'),
    },
    props: { items: { type: Array, default: () => [] } },
  };
</script>

<style lang="sass">
  .box-select-list
    gap: 2.5rem
</style>
